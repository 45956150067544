import Link from 'next/link'
import React from 'react'
import { usePageLink } from '../src/hooks/usePageLink'
import { useTranslate } from '../src/hooks/useTranslate'
import { SpecialPageType } from '../src/model/fragments'
import { ConditionalWrap } from './ConditionalWrap'
import { ErrorNotice } from './Error'
import { ReplacePlaceholders } from './ReplacePlaceholders'
import { Seo } from './Seo'

export const MissingContent: React.FunctionComponent = () => {
	const homepageLink = usePageLink(SpecialPageType.homepage)
	const translate = useTranslate()

	return (
		<>
			<Seo
				seo={{
					title: translate('error-404-title'),
					description: undefined,
					ogTitle: undefined,
					ogImage: undefined,
					ogDescription: undefined,
				}}
			/>
			<ErrorNotice title={translate('error-404-title')}>
				<ReplacePlaceholders
					main={translate('error-404-description')}
					placeholders={{
						search: (
							<ConditionalWrap
								condition={Boolean(homepageLink)}
								wrap={(children) => (
									<Link {...homepageLink!}>
										<a>{children}</a>
									</Link>
								)}
							>	
								<>{translate('error-404-description-search')}</>
							</ConditionalWrap>
						), // @TODO: link to search page
						homepage: (
							<ConditionalWrap
								condition={Boolean(homepageLink)}
								wrap={(children) => (
									<Link {...homepageLink!}>
										<a>{children}</a>
									</Link>
								)}
							>
								<>{translate('error-404-description-homepage')}</>
							</ConditionalWrap>
						),
					}}
				/>
			</ErrorNotice>
		</>
	)
}
