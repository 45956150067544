import React from 'react'
import { ModalContent } from './ModalManager'

export interface ErrorProps {
	title: string | React.ReactNode
}

export const ErrorNotice: React.FunctionComponent<ErrorProps> = (props) => {
	const { title, children } = props

	return (
		<ModalContent>
			<section className="errorNotice">
				<div className="errorNotice-in">
					<h1 className="errorNotice-title">{title}</h1>
					<div className="errorNotice-content">{children}</div>
				</div>
			</section>
		</ModalContent>
	)
}
