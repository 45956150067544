import React from 'react'
import reactStringReplace from 'react-string-replace'

export interface ReplacePlaceholdersProps {
	main: string
	placeholders: {
		[key: string]: string | React.ReactNode
	}
}

export const ReplacePlaceholders: React.FunctionComponent<ReplacePlaceholdersProps> =
	({ main, placeholders }) => {
		return (
			<>
				{reactStringReplace(main, /\{([a-zA-Z]+)\}/, (match, i) => (
					<React.Fragment key={i}>
						{match in placeholders ? placeholders[match] : `{${match}}`}
					</React.Fragment>
				))}
			</>
		)
	}
