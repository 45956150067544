import cn from 'classnames'
import React from 'react'

export interface WidthBoundProps {
	size?: undefined | 'narrow' | 'wide' | 'normal'
}

export const WidthBound: React.FunctionComponent<WidthBoundProps> = ({
	children,
	size = 'normal',
}) => (
	<div className={cn('widthBound', size && `view-size-${size}`)}>
		<div className="widthBound-in">{children}</div>
	</div>
)
