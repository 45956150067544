export enum FileExtension {
	pdf = 'PDF',
	jpg = 'JPG',
	unknown = '',
}

export function mimeTypeToFileExtension(mimeType: string) {
	if (mimeType === 'application/pdf') {
		return FileExtension.pdf
	}
	if (mimeType === 'image/jpeg') {
		return FileExtension.jpg
	}
	return FileExtension.unknown
}
