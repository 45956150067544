import cn from 'classnames'
import React from 'react'

export interface SpacerProps {
	initial?: number
	break320?: number
	break500?: number
	break800?: number
	break1000?: number
	break1200?: number
	break1400?: number
}

const getBreakCustomProperties = (props: SpacerProps): React.CSSProperties => {
	const breaks = [
		{ name: 'initial', value: props.initial },
		{ name: '320', value: props.break320 },
		{ name: '500', value: props.break500 },
		{ name: '800', value: props.break800 },
		{ name: '1000', value: props.break1000 },
		{ name: '1200', value: props.break1200 },
		{ name: '1400', value: props.break1400 },
	]

	for (let i = 1; i < breaks.length; i++) {
		if (
			typeof breaks[i].value === 'undefined' &&
			typeof breaks[i - 1].value !== 'undefined'
		) {
			breaks[i].value = breaks[i - 1].value
		}
	}

	const styles: React.CSSProperties = {}
	breaks.forEach((item) => {
		;(styles as any)[`--spacer-break-${item.name}`] = item.value
	})

	return styles
}

export const Spacer: React.FunctionComponent<SpacerProps> = (props) => {
	return (
		<div className={cn('spacer')} style={getBreakCustomProperties(props)}></div>
	)
}
