import assert from 'assert'
import classNames from 'classnames'
import React, { MouseEventHandler, ReactNode } from 'react'
import { useTranslate } from '../src/hooks/useTranslate'
import { ImageFragment, LinkType } from '../src/model/fragments'
import { FileExtension } from '../src/utils/mimeTypeToFileExtension'
import { CardPicture } from './CardPicture'
import { Picture, PictureEncode } from './Picture'

export interface CardDownloadProps {
	title: string | ReactNode
	href?: string
	loading?: boolean
	onClick?: MouseEventHandler<HTMLAnchorElement>
	extension: FileExtension
	imageIcon?: ImageFragment
	svgIcon?: ReactNode
}

export const CardDownload: React.FC<CardDownloadProps> = ({
	title,
	href,
	imageIcon,
	svgIcon,
	loading,
	onClick
}) => {
	const translate = useTranslate()
	const iconContain = Boolean(imageIcon)
	assert((href || onClick) && !(href && onClick));

	return (
		<div className={classNames('cardDownload', loading && 'cardDownload--loading')}>
			<CardPicture
				title={	
					<>
						{title}
					</>
				}
				picture={ (imageIcon &&
					<div className={classNames('cardDownload-icon', 'cardDownload-icon-' + (imageIcon.intrinsicEncode ?? PictureEncode.png))}>
						<Picture
								alt={translate('download-thumbnail-alternative')}
								mode={iconContain ? 'contain' : 'default'}
								image={imageIcon}
								encode={imageIcon ? (imageIcon.intrinsicEncode ?? PictureEncode.png) : PictureEncode.svg}
								width={imageIcon ? 50 : 45}
								height={imageIcon ? 45 : 45}
							/>
					</div>) || (svgIcon && svgIcon)
				}
				hrefText="Stáhnout"
				linkProps={href ? {
					type: LinkType.external,
					externalLink: href,
				} : undefined}
				onClick={onClick}
			/>
		</div>
	)
}
