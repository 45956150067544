import cn from 'classnames'
import React from 'react'
import { useTranslate } from '../src/hooks/useTranslate'
import { ImageFragment } from '../src/model/fragments'
import { Picture, PictureEncode } from './Picture'

export enum CardView {
	default = 'default',
	small = 'small',
}

export interface CardProps {
	clickable?: boolean
	view?: CardView
	heroImage?: ImageFragment
}

export const Card: React.FunctionComponent<CardProps> = ({
	view,
	clickable,
	heroImage,
	children,
}) => {
	const translate = useTranslate();
	
	return (
		<div
			className={cn(
				'card',
				`view-${view || CardView.default}`,
				clickable && 'view-clickable',
			)}

			data-download-text={ translate('card-download') }
		>
			{heroImage && (
				<div className="card-heroImage">
					<Picture
						image={heroImage}
						width={300}
						height={180}
						alt=""
						mode={'contain'}
						encode={PictureEncode.png}
					/>
				</div>
			)}
			<div className="card-in">{children}</div>
		</div>
		);
}
