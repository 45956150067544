import cn from 'classnames'
import React, { MouseEventHandler } from 'react'
import { Card, CardView } from './Card'
import { FlexiLink, FlexiLinkProps } from './FlexiLink'

export enum CardPictureView {
	download = 'download',
	news = 'news',
}

interface CardPictureProps {
	title: React.ReactNode
	picture: React.ReactNode
	linkProps?: FlexiLinkProps
	hrefText: string
	view?: CardPictureView
	onClick?: MouseEventHandler<HTMLAnchorElement>
}

export const CardPicture: React.FC<CardPictureProps> = (props) => {
	const { title, picture, linkProps, hrefText, view, onClick } = props

	return (
		<Card clickable={onClick ? true : false} view={CardView.small}>
			<div
				className={cn(
					'cardPicture',
					`view-${view || CardPictureView.download}`,
				)}
			>

{
				linkProps && 
					<FlexiLink {...linkProps}>
						<a className="cardPicture-link" aria-label={hrefText} />
					</FlexiLink>
				}
				{
					!linkProps && onClick && 
					<a className="cardPicture-link" onClick={onClick} aria-label={hrefText} />
				}
				
				<div className="cardPicture-image">{picture}</div>
				<div className="cardPicture-content">
					<div className="cardPicture-head">
						<h4 className="cardPicture-title">{title}</h4>
						{props.children}
					</div>
					{
						linkProps && 
						<FlexiLink {...linkProps}>
							<a className="cardPicture-link-text">{hrefText}</a>
						</FlexiLink>
					}
				</div>
			</div>
		</Card>
	)
}
