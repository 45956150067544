import cn from 'classnames'
import React, { useMemo } from 'react'
import { SpecialPageType } from '../src/model/fragments'
import { useLocalization } from '../src/hooks/useLocaleFormatter'
import { usePageLink } from '../src/hooks/usePageLink'
import { useTranslate } from '../src/hooks/useTranslate'
import { DocumentFragment, ImageFragment } from '../src/model/fragments'
import { getLinkWithQuery } from '../src/model/urls'
import { mimeTypeToFileExtension } from '../src/utils/mimeTypeToFileExtension'
import { zipDownloadProxy } from '../src/utils/zipDownloadProxy'
import { CardDownload, CardDownloadProps } from './CardDownload'
import { RadioButtons } from './RadioButtons'

const WITHOUT_CATEGORY_KEY = 'other'

const Items: React.FunctionComponent<{
	items: Document[]
	fallbackIcon?: ImageFragment
}> = ({ items, fallbackIcon }) => (
	<div className="documents-items">
		{items
			.sort((a, b) => (typeof a.title === 'string' && a.title) ? a.title.localeCompare(b.title as string, 'cs') : 1)
			.map((item) => (
				<div className="documents-item" key={item.id}>
					<CardDownload
						extension={item.extension}
						title={item.title}
						href={
							item.fileType === 'application/x-zip-compressed'
								? zipDownloadProxy(item.fileId, item.fileName)
								: item.href
						}
						imageIcon={item.imageIcon || fallbackIcon}
					/>
				</div>
			))}
	</div>
)

export interface DocumentsProps {
	showTabs?: boolean
	groupId?: null | string
	mode?: 'simple' | 'categories' 
	items: DocumentFragment[]
}

interface Document extends CardDownloadProps {
	id: string
	fileId: string
	fileName: string
	fileType: string
}

interface Category {
	id: string
	name: string
	order: number | null
	documents: Document[]
	icon?: ImageFragment
}

interface CategoryGroup {
	id: string
	name: string
	order: number | null
	documents: Document[]
	categories: {
		[id: string]: Category
	}
	icon?: ImageFragment
}

interface CategoryGroups {
	[id: string]: CategoryGroup
}

const sortCategories = <T extends { name: string; order: number | null }>(
	a: T,
	b: T,
): number => {
	if (a.order !== null || b.order !== null) {
		return (
			(a.order ?? Number.MAX_SAFE_INTEGER - 1) -
			(b.order ?? Number.MAX_SAFE_INTEGER - 1)
		)
	}

	// Alphabetical order
	return a.name.localeCompare(b.name)
}

const useCategoryGroups = (items: DocumentFragment[]): CategoryGroup[] => {
	const localization = useLocalization()
	const translate = useTranslate()
	return useMemo(() => {
		const categoryGroups: CategoryGroups = {
			[WITHOUT_CATEGORY_KEY]: {
				id: WITHOUT_CATEGORY_KEY,
				name: translate('downloads-uncategorised-label'),
				documents: [],
				categories: {},
				order: Number.MAX_SAFE_INTEGER,
			},
		}

		items.forEach((item) => {

			const data = localization.extractLocale(item.locales)
			const document: Document = {
				id: item.id,
				extension: mimeTypeToFileExtension(data?.file?.type || ''),
				title: data?.name || '',
				href: data?.file?.url || '',
				fileId: data?.file?.id || '',
				fileName: data?.file?.name || '',
				fileType: data?.file?.type || '',
				imageIcon: item.ownIcon,
			}

			if (item.categories.length === 0) {
				categoryGroups[WITHOUT_CATEGORY_KEY].documents.push(document)
				return
			}

			item.categories.forEach((category) => {
				const data = localization.extractLocale(category.locales)


				if (category.parent) {
					if (!(category.parent.id in categoryGroups)) {

						categoryGroups[category.parent.id] = {
							id: category.parent.id,
							name:
								localization.extractLocale(category.parent.locales)?.name || '',
							categories: {},
							order: category.parent.order ?? null,
							documents: [],
							icon: category.parent.icon,
						}
					}
					if (!(category.id in categoryGroups[category.parent.id].categories)) {

						categoryGroups[category.parent.id].categories[category.id] = {
							id: category.id,
							name: data?.name || '',
							order: category.order ?? null,
							documents: [],
							icon: category.icon || category.parent.icon,
						}
					}
					categoryGroups[category.parent.id].categories[
						category.id
					].documents.push(document)
					return
				}

				if (!(category.id in categoryGroups)) {

					categoryGroups[category.id] = {
						id: category.id,
						name: data?.name || '',
						documents: [],
						order: category.order ?? null,
						categories: {},
						icon: category.icon,
					}
				}

				categoryGroups[category.id].documents.push(document)
			})
		})

		if (categoryGroups[WITHOUT_CATEGORY_KEY].documents.length === 0) {
			delete categoryGroups[WITHOUT_CATEGORY_KEY]
		}

		// Pokud nadkategorie nemá dokumenty a jen jednu podkategorii, předstírej, že podkategorie je top level
		/*for (let key in categoryGroups) {
			const categories = Object.values(categoryGroups[key].categories)
			if (
				categoryGroups[key].documents.length === 0 &&
				categories.length === 1
			) {
				categoryGroups[key].categories = {}
				categories.forEach((category) => {
					categoryGroups[key].name = category.name
					categoryGroups[key].documents = category.documents
				})
			}
		}*/

		const sortedGroups = Object.values(categoryGroups).sort(sortCategories)

		return sortedGroups
	}, [items, localization, translate])
}

export const Documents: React.FunctionComponent<DocumentsProps> = ({
	showTabs = false,
	groupId = null,
	mode = 'categories',
	items,
}) => {
	const localization = useLocalization();
	const categoryGroups = useCategoryGroups(items)

	groupId = groupId === null ? null : groupId!.toString();
	const [initialIndex] = React.useState(
		() =>  1 + (categoryGroups.findIndex((group) => group.id == groupId)),
	)
	
	const [selectedTab, setSelectedTab] = React.useState(initialIndex)
	const precollapsed = showTabs && selectedTab !== 0

	const downloadsAllLink = usePageLink(SpecialPageType.downloads)!
	const downloadsGroupLink = (group: null | string) => ({
		...(group === null
			? downloadsAllLink
			: getLinkWithQuery(downloadsAllLink, { group })),
		scroll: false,
	})

	const simpleViewDocuments = React.useMemo(() => {
		if(mode == 'categories')
		{
			return [];
		}

		return items.map(item => {
			const data = localization.extractLocale(item.locales);

			return {
				id: item.id,
				extension: mimeTypeToFileExtension(data?.file?.type || ''),
				title: data?.name || '',
				href: data?.file?.url || '',
				fileId: data?.file?.id || '',
				fileName: data?.file?.name || '',
				fileType: data?.file?.type || '',
				imageIcon: item.ownIcon,
			} as Document;
		});
	}, [items, mode, localization]);

	return (
		<div className="documents">
			{showTabs && mode == 'categories' && (
				<RadioButtons
					items={[
						{ text: 'Vše', linkProps: downloadsGroupLink(null) },
						...categoryGroups.map((group) => ({
							text: group.name,
							linkProps: downloadsGroupLink(group.id),
						})),
					]}
					onChange={setSelectedTab}
					initialIndex={initialIndex}
				/>
			)}
			{
				mode == 'simple' && 
				<>
					<ul className="documents-list">
						<Items items={simpleViewDocuments} />
					</ul>
				</>
			}
			{
				mode == 'categories' && 
				<ul className="documents-lists">
					{categoryGroups
						.filter((_, i) => selectedTab === 0 || selectedTab === i + 1)
						.map((group) => {
							const precollapsedGroup =
								precollapsed && Object.keys(group.categories).length !== 0

							return (
								<Group
									key={group.id}
									group={group}
									precollapsed={precollapsed}
									categoriesPrecollapsed={precollapsedGroup}
								/>
							)
						})}
				</ul>
			}
			
		</div>
	)
}

const Category: React.FunctionComponent<{
	category: Category
	precollapsed?: boolean
}> = ({ category, precollapsed = false }) => {
	const { isExpanded, toggleExpanded } = useIsExpanded(precollapsed)
	const anchor = useAnchor(category.id)

	return (
		<ul className={cn('documents-categories', isExpanded && 'is-expanded')}>
			<li className="documents-category">
				<h2 className="documents-category-title" id={anchor}>
					<a
						href={`#${anchor}`}
						className="documents-category-title-in"
						onClick={toggleExpanded}
					>
						{category.name}
						{category.documents.length > 0 && (
							<> ({category.documents.length})</>
						)}
					</a>
				</h2>
				{isExpanded && (
					<Items items={category.documents} fallbackIcon={category.icon} />
				)}
			</li>
		</ul>
	)
}

const useIsExpanded = (precollapsed: boolean) => {
	const [isExpanded, setIsExpanded] = React.useState(!precollapsed)

	const toggleExpanded = React.useCallback(() => {
		setIsExpanded(!isExpanded)
	}, [isExpanded])

	React.useEffect(() => {
		if (isExpanded === precollapsed) {
			setIsExpanded(!precollapsed)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [precollapsed])

	return { isExpanded, toggleExpanded }
}

const useAnchor = (id: string) => `id-${id.substr(0, 6)}`

const Group: React.FunctionComponent<{
	group: CategoryGroup
	precollapsed?: boolean
	categoriesPrecollapsed?: boolean
}> = ({ group, precollapsed = false, categoriesPrecollapsed = false }) => {
	const { isExpanded, toggleExpanded } = useIsExpanded(precollapsed)
	const anchor = useAnchor(group.id)

	const [mounted, setMounted] = React.useState<boolean>(false);
	const [hash, setHash] = React.useState<string>('');
	React.useEffect(() => 
	{
		if(mounted)
		{
			return;
		}
		setMounted(true);

		if(window.location.hash)
		{
			setHash(window.location.hash.replace('#id-', ''));
		}
	});

	return (
		<li className={cn('documents-group', isExpanded && 'is-expanded')}>
			<section>
				<h1 className="documents-title" id={anchor}>
					<a
						href={`#${anchor}`}
						className="documents-title-in"
						onClick={toggleExpanded}
					>
						{group.name}
						{group.documents.length > 0 && <> ({group.documents.length})</>}
					</a>
				</h1>
				{isExpanded && (
					<Items items={group.documents} fallbackIcon={group.icon} />
				)}
				{Object.values(group.categories)
					.sort(sortCategories)
					.map((category) => (
						<Category
							key={category.id}
							category={category}
							precollapsed={categoriesPrecollapsed && (!hash || hash != category.id)}
						/>
					))}
			</section>
		</li>
	)
}
