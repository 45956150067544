import { LinkProps } from 'next/link'
import React from 'react'
import { Band } from './Band'
import { Button, ButtonView } from './Button'

interface RadioButtonsProps {
	items: Array<{
		text: React.ReactNode
		icon?: React.ReactNode
		linkProps?: LinkProps
	}>
	index?: number
	initialIndex?: number
	desiredIndex?: number
	onChange?: (selectedIndex: number) => void
}

export const RadioButtons: React.FunctionComponent<RadioButtonsProps> = ({
	items,
	onChange,
	index,
	desiredIndex,
	initialIndex = 0,
}) => {
	const [localIndex, setLocalIndex] = React.useState(initialIndex)
	const onClick = React.useCallback(


		(index: number) => () => {
			setLocalIndex(index)
			if (index !== localIndex) {
				if (onChange) {
					onChange(index)
				}
			}
		},
		[onChange, localIndex, setLocalIndex],
	)

	React.useEffect(() => {
		if(desiredIndex !== undefined)
		{
			setLocalIndex(desiredIndex);
		}
	}, [desiredIndex]);

	const mergedIndex = index === undefined ? localIndex : index

	return (
		<Band>
			<div className="radioButtons">
				<div className="radioButtons-in">
					{items.map((item, i) => (
						<div key={i} className="radioButtons-item">
							<Button
								view={ButtonView.tabs}
								active={i === mergedIndex}
								onClick={onClick(i)}
								linkProps={item.linkProps}
								icon={item.icon}
							>
								{item.text}
							</Button>
						</div>
					))}
				</div>
			</div>
		</Band>
	)
}
